const getCommercials = async (password, signal) => {
  const url =
    "https://control.spaingrupo.com/wp-json/private/v1/assets/?password=" +
    password;

  const resp = await fetch(url, { signal: signal });
  const returner = await resp.json();

  return returner || [];
};

export default getCommercials;
