import SectionTitle from "../../components/SectionTitles/SectionTitle";
import { Link } from "react-router-dom";
import React from "react";

const CallToAction = ({
  title = "",
  desc = "",
  btnLink = "",
  btnLabel = "",
  bg = "",
}) => {
  return (
    <div
      className="section section-bg-image section-padding-t110-b120 newsletter-section overlay-two"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 m-auto">
            <div className="cta-content text-center">
              <SectionTitle
                titleOption="color-light text-center mb-0"
                title={title}
                subTitle={desc}
              />
              <Link
                to={process.env.PUBLIC_URL + "/" + btnLink}
                className="btn btn-primary btn-hover-secondary mt-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                {btnLabel}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
