import React from "react";
import ScrollToTop from "../components/ScrollToTop.jsx";
import SectionTitle from "../components/SectionTitles/SectionTitle";
import SEO from "../components/SEO";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import ContactInformationThree from "../container/ContactInformation/ContactInformationThree";
import Footer from "../container/Footer/Footer";
import HomeSkillWithVideo from "../container/HomeSkillWithVideo/HomeTextWithVideo";
import SingleAssetTwoPhotos from "../container/Work/SingleAssetTwoPhotos";
import useGetCommercials from "../hooks/useGetCommercials.js";
import Header from "../partials/header/Header";

const Service = () => {
  const commercials = useGetCommercials();
  return (
    <React.Fragment>
      <SEO title="Assets Management - SpainGrupo, Investments in Spain" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-three.jpg"
        title="We work with the most relevant assets in the market"
        content="Home"
        contentTwo="Asset Management"
      />
      <HomeSkillWithVideo />
      <SectionTitle
        headingOption="fz-32 mt-6 mb-5"
        title="Discover our most profitable investments"
        subTitle="The key is that we treat each client individually,
                    proposing to you only those in which we trust"
        id="main"
      />
      {commercials &&
        commercials.map((single, key) => {
          return <SingleAssetTwoPhotos data={single} key={single.id} />;
        })}
      <ContactInformationThree />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Service;
