import React from "react";
import Funfact from "../Funfact/Funfact";

const HomeAboutThree = ({ title, description, info = false }) => {
  /*     const [isOpen, setOpen] = useState(false) */
  return (
    <div className="video-section section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 m-auto" data-aos="fade-up">
            <div className="about-content-full text-center mb-lg-10 mb-0">
              <h2 className="title">{title}</h2>
              <p>{description}</p>
            </div>
          </div>
          {info && (
            <>
              <Funfact />

              <div className="col-12">
                <div className="video-popup-area">
                  <div className="skill-video big">
                    <img
                      className="image"
                      src="https://res.cloudinary.com/ddwoawsvk/image/upload/v1623388435/refinery-image_nbpukt.jpg"
                      alt="video popup"
                    />
                    {/*  <button className="icon" onClick={()=> setOpen(true)}><i className="fas fa-play"></i></button>
                                        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="if-2M3K1tqk" onClose={() => setOpen(false)}/> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeAboutThree;
