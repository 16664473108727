import { useEffect, useState } from "react";
import getPrivateCommercial from "../utils/getPrivateCommercial";
const useGetPrivateCommercial = (id, password) => {
  const [commercial, setCommercial] = useState({});

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (password) {
      getPrivateCommercial(id, password, signal).then((asset) => {
        setCommercial(asset);
      });
    }

    return () => abortController.abort();
  }, [password]);

  return commercial;
};

export default useGetPrivateCommercial;
