import React, { useState } from "react";
import {Link} from "react-router-dom";
import ModalVideo from "react-modal-video";



const HomeTextWithVideo = () => {
    /* const [isOpen, setOpen] = useState(false) */

    return (
        <div className="video-section section section-padding">

            <div className="container">

                <div className="row align-items-center">
                    <div className=" col-xl-6 col-lg-6 col-12">
                        <div className="video-popup-area">
                            <div className="skill-video">
                                <img className="image" src="https://res.cloudinary.com/ddwoawsvk/image/upload/v1623386518/assets-magament-image_at9y5b.jpg" alt="video popup" />
                                {/* <button className="icon" onClick={()=> setOpen(true)}><i className="fas fa-play"></i></button>
                                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="eS9Qm4AOOBY" onClose={() => setOpen(false)}/> */}
                            </div>
                        </div>
                    </div>
                    <div className="offset-xl-1 col-xl-5 col-lg-6 col-12" data-aos="fade-up">
                        <div className="skill-with-video-content">
                            <div className="section-title-two mb-8">
                                <span className="sub-title">Several decades of experience</span>
                                <h3 className="title">A wide and continuously updated portfolio of options</h3>
                            </div>

                            <p>Our meticulous process starts from searching and studying possible investments. And choosing only those that we consider the best in terms of opportunity and profitability.</p>
                            <br></br>
                            <Link to={process.env.PUBLIC_URL + "/contact"} className="btn btn-primary btn-hover-secondary"> Get in touch now</Link>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}



export default HomeTextWithVideo;
