import React from "react";
import ScrollToTop from "../components/ScrollToTop.jsx";
import SEO from "../components/SEO";
import AboutFive from "../container/About/AboutFive";
import HomeAboutThree from "../container/About/HomeAboutThree";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import Header from "../partials/header/Header";

const BlogGrid = () => {
  return (
    <React.Fragment>
      <SEO title="I+D+i & product development - SpainGrupo, Investments in Spain" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-four.jpg"
        title="Investigation, development & Innovation"
        content="Home"
        contentTwo="I+D+i"
      />
      <HomeAboutThree
        title="Product development and Innovation"
        description="We have experience in designing and developing ideas and products that have been patented and then put into production"
      />
      <AboutFive
        title="The reinvention of the parking industry"
        subtitle="Soft Car Protect"
        description="Too many people were parking wider than they are supposed to and the parking was loosing money. With our product you are delimiting the parking spaces, visually and physically."
        link="https://softcarprotect.com/"
        firstImage="https://res.cloudinary.com/ddwoawsvk/image/upload/v1623494819/Soft-Car-Protect-orignal_t7kfal.jpg"
        secondImage="https://res.cloudinary.com/ddwoawsvk/image/upload/v1623494861/Soft-Car-Protect-orignal12_ucmckt.jpg"
      />
      <AboutFive
        title="Best Quality Wooden toys"
        subtitle="Dommy Toys"
        description="Our toys are completely ecological. They come in many variations so you can make all the houses you want which are fully customazible. You can paint it with different colours!"
        link="https://www.domy.toys/"
        firstImage="https://www.domy.toys/wp-content/uploads/2020/12/%D0%A0%D0%945-2.jpg"
        secondImage="https://www.domy.toys/wp-content/uploads/2020/11/IMG_2018-copy-scaled.jpg"
      />
      {/* <AboutFive 
                title="Product design and Personalization" 
                subtitle="Diana" 
                description="Our toys are completely biodegrable. They came in many Variations so you can make all the houses you want and are fully customazible. You can paint it with different colours!"
                link="https://www.domy.toys/"
                firstImage="https://upload.wikimedia.org/wikipedia/commons/5/5e/Diana%2C_Princess_of_Wales_1997_%282%29.jpg"
                secondImage="https://ep01.epimg.net/elpais/imagenes/2019/08/13/gente/1565681781_705392_1565682842_noticia_normal.jpg"
                /> */}
      <CallToAction
        title="Do you need help with product development?"
        desc="If you have an idea and you need help to carry it out, contact us and we will help you to make it a reality"
        btnLink="contact"
        btnLabel="Get in Touch"
        bg="https://res.cloudinary.com/ddwoawsvk/image/upload/v1623396854/IMG_6589_1_xx5dir.jpg"
      />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default BlogGrid;
