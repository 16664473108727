import React, { Fragment, useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";
import AccountContext from "../../contexts/AccountContext";

const PasswordForm = () => {
  const { doLogin, loading } = useContext(AccountContext);
  return (
    <Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          doLogin(e.target.password.value, e.target.email.value);
          e.target.reset();
        }}
      >
        <div className="row mb-n4 position-relative">
          <div className="col-md-12 col-12 mb-4">
            <input type="email" placeholder="Your Email *" name="email" />
          </div>
          <div className="col-md-12 col-12 mb-4">
            <input
              type="password"
              placeholder="Your Password *"
              name="password"
            />
          </div>
          <div className="col-12 text-center mb-4">
            <button
              type="submit"
              className="btn btn-primary btn-hover-secondary"
            >
              Login
            </button>
            <div className="mt-5">Or request access through this link:</div>
            <Link
              to={
                process.env.PUBLIC_URL + "/asset-management/#request-more-info"
              }
              className="btn d-block mt-2 mx-auto  "
              style={{
                fontWeight: "bold",
                color: "#030f27",
                fontSize: "1.2rem",
              }}
            >
              {">>"} Request Access
            </Link>
          </div>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#ffffff94",
                zIndex: 3,
                backdropFilter: "blur(2px)",
              }}
            ></div>
          )}
        </div>
      </form>
      <p className="form-messege"></p>
    </Fragment>
  );
};

export default PasswordForm;
