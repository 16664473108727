import SectionTitleBig from "../SectionTitles/SectionTitleBig";
import React from "react";

const HomeAboutTwo = () => {
  return (
    <div id="cases" className="section section-padding-top">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-content-area mt-0 mb-20 mb-md-10 ">
              <SectionTitleBig title="We think strategically to cover all angles" />
            </div>
          </div>

          <div
            className="col-xl-6 col-lg-6 col-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="about-image-area about-shape-animation right-0 mr-0">
              <div className="about-image js-tilt">
                <img
                  src={process.env.PUBLIC_URL + "images/about/about-1.jpg"}
                  alt=""
                />
              </div>
              <div className="about-image js-tilt">
                <img
                  src={process.env.PUBLIC_URL + "images/about/about-2.jpg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAboutTwo;
