import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";

const ServiceBox = ({ data, classOption }) => {
    return (
        <div className={`service-box text-center ${classOption}`}>
            
            <div className="thumbnail">
                <a className="image" href={data.link}>
                    <img src={data.image} alt="Blog Image" />
                </a>
            </div>
            
            <div className="content mt-5">
                <h3 className="title">{data.title}</h3>
                <div className="desc">
                    <p>{data.desc}</p>
                </div>
                <Link to={data.link} className="link">{`>> ${data.pageLink}`}</Link>
            </div>
        </div>
    )
}

ServiceBox.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string
};

ServiceBox.defaultProps = {
    classOption: "service-box text-center",
};

export default ServiceBox;
