import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import IntroTwo from '../container/IntroSlider/IntroTwo';
import HomeAboutTwo from '../components/About/HomeAboutTwo.jsx';
import ServiceHomeBox from '../container/service/ServiceHomeBox';
import HomeSkillWithVideo from '../container/HomeSkillWithVideo/HomeSkillWithVideo';
import ContactInformationTwo from '../container/ContactInformation/ContactInformationTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const HomeTwo = () => {
    return (
        <React.Fragment>
            <SEO 
                title="SpainGrupo - The wise choice for Investments in Spain"
                />
            <Header dark={true} />
            <IntroTwo />
            <HomeAboutTwo />
            <ServiceHomeBox />
            <HomeSkillWithVideo />
            <ContactInformationTwo  classOption="section-padding-bottom" />
            {/* <BrandContainer classOption="section-padding-bottom" /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default HomeTwo;