import React, { Fragment } from "react";
import { HashLink as Link } from "react-router-hash-link";

const ProjectForm = () => {
  return (
    <Fragment>
      <form
        action="https://getform.io/f/254e858f-dcf1-49af-bdb6-f033def85d63"
        method="POST"
      >
        <div className="row mb-n4">
          <div className="col-md-12 col-12 mb-4">
            <input type="text" placeholder="Your Name *" name="name" />
          </div>
          <div className="col-md-12 col-12 mb-4">
            <input type="email" placeholder="Email *" name="email" />
          </div>
          <div className="col-12 mb-6">
            <textarea name="message" placeholder="Message"></textarea>
          </div>
          <div className="col-12 text-center mb-4">
            <button className="btn btn-primary btn-hover-secondary">
              Request Access
            </button>
          </div>
          <div className="col-12 text-center mb-4">
            <Link
              to={process.env.PUBLIC_URL + "/private/#main"}
              className="btn d-block mt-2 mx-auto  "
              style={{
                fontWeight: "bold",
                color: "#030f27",
                fontSize: "1.2rem",
              }}
            >
              {">>"} Access now
            </Link>{" "}
          </div>
        </div>
      </form>
      <p className="form-messege"></p>
    </Fragment>
  );
};

export default ProjectForm;
