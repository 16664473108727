import PropTypes from "prop-types";
import React from 'react';

const SectionTitleBig = ({ title, classOption}) => {
    return (
        <div className={`section-title-big ${classOption}`} data-aos="fade-up">
            <h2 className="title" dangerouslySetInnerHTML={{__html: title}} />
        </div>
    )
}

SectionTitleBig.propTypes = {
    title: PropTypes.string,
    classOption: PropTypes.string
};
SectionTitleBig.defaultProps = {
    classOption: "section-title-big"
};

export default SectionTitleBig;
