import PropTypes from "prop-types";
import React, { useContext } from "react";
import ScrollToTop from "../components/ScrollToTop.jsx";
import SEO from "../components/SEO";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import WorkDetailsContainer from "../container/Work/WorkDetailsContainer";
import AccountContext from "../contexts/AccountContext.js";
import useGetCommercial from "../hooks/useGetCommercial.js";
import useGetPrivateCommercial from "../hooks/useGetPrivateCommercial.js";
import Header from "../partials/header/Header";

const WorkDetails = ({
  match: {
    params: { id },
  },
}) => {
  const { password } = useContext(AccountContext);
  const data = useGetCommercial(id);

  return (
    <React.Fragment>
      <SEO
        title={`${
          data?.title?.rendered || "Investment Details"
        } - SpainGrupo, Investments in Spain`}
      />
      <Header />
      <Breadcrumb
        rawImage={data?.acf?.gallery[0]}
        title={data?.title?.rendered}
        content="Home"
        contentTwo="Investment"
      />
      <WorkDetailsContainer data={data || {}} />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

WorkDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default WorkDetails;
