import PropTypes from "prop-types";
import React from "react";

const SectionTitle = ({
  title,
  subTitle,
  titleOption,
  headingOption,
  link = false,
  ...props
}) => {
  return (
    <div
      className={`section-title ${titleOption}`}
      data-aos="fade-up"
      {...props}
    >
      <h2 className={`title ${headingOption}`}>
        {(link && (
          <a href={link} rel="_blank">
            {title}{" "}
            <i className="far fa-angle-right" style={{ fontSize: "20px" }} />
          </a>
        )) ||
          title}
      </h2>

      <p className="sub-title">{subTitle}</p>
    </div>
  );
};

SectionTitle.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  titleOption: PropTypes.string,
  headingOption: PropTypes.string,
};
SectionTitle.defaultProps = {
  titleOption: "text-center",
  headingOption: "title",
};

export default SectionTitle;
