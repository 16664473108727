import React from 'react';
import contactDataTwo from '../../data/contactInfo/contactInfoTwo.json';
import ContactInfoItem from '../../components/ContactInfo/ContactInfoItemTwo.jsx';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import ProjectForm from '../../components/ProjectForm/ProjectForm';


const ContactInformationTwo = () => {
    return (
        <div className="section section-padding contact-section">

            <div className="container">
                <div className="row row-cols-lg-2 row-cols-1 align-items-center">
                    <div className="col" data-aos="fade-up">
                        <div className="contact-Information mr-xl-7">
                            <SectionTitleTwo 
                                subTitle="Communication is key for the growth"
                                title="We love to hear good proposals from good people"
                            />

                            {contactDataTwo && contactDataTwo.map((single, key) => {
                                return(
                                    <React.Fragment key={key}>
                                        <ContactInfoItem classOption="ct-info-two" data={single} key={key} />
                                    </React.Fragment>
                                ); 
                            })}
                        </div>
                    </div>
                    <div className="col mt-lg-0 mt-md-10 mt-8" data-aos="fade-up">
                        <div className="contact-form-area box-shadow">
                            <SectionTitle
                                titleOption="section-title text-center mb-7"
                                headingOption="title fz-28"
                                title="Let’s talk"
                                subTitle="We have made it easy for clients to reach us and get the ball rolling"
                            />

                            <ProjectForm />
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ContactInformationTwo
