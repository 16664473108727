import React from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
const AboutFour = () => {
  return (
    <div className="section section-padding-top about-section-padding-bottom">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-content-area mt-0 mb-md-10 mb-10">
              <SectionTitleTwo
                subTitle="Where do you want to live?"
                title="Take a look at our hand picked property selection in Costa del Sol"
              />

              <div className="row">
                <div className="col mb-12">
                  <div className="about-funfact">
                    <h6 className="text">
                      SpainDom - Our real estate department
                    </h6>
                    <p>
                      We offer a complete service regarding residential tourism,
                      helping our clients to choose the ideal property. But also
                      we help establishing your second life here. We are experts
                      in administrative procedures and we can advise you on all
                      aspects of real estate and residence in Spain.
                    </p>
                  </div>
                  <a
                    href="https://spaindom.com/"
                    target="_blank"
                    className="btn btn-primary btn-hover-secondary mt-6"
                  >
                    Discover SpainDom {">>"}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-xl-6 col-lg-6 col-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="about-image-area about-shape-animation right-0 mr-0">
              <div className="about-image js-tilt">
                <img src="https://i.imgur.com/yCnVvfQ.jpg" alt="" />
              </div>
              <div className="about-image js-tilt">
                <img src="https://i.imgur.com/mzamVWa.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
