import React from 'react'
import {NavLink} from 'react-router-dom';

const NavBar = () => {
    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/asset-management"}><span className="menu-text">Asset Management</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/commodities"}><span className="menu-text">Commodities</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/residential"}><span className="menu-text">Residential</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/innovation"}><span className="menu-text">I+D+I</span></NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar
