import React from "react";
import BlogItem from "../../components/Blog/BlogItem";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import useGetProperties from "../../hooks/useGetProperties";

const PageBlog = () => {
  const properties = useGetProperties();
  return (
    <div className="section section-padding fix">
      <div className="container">
        <br />
        <SectionTitle
          headingOption="fz-32 mt-6 mb-5"
          title="Our latest Villas for sale"
          subTitle=""
          link="https://spaindom.com/listing-properties/?property_type%5B%5D=villa&price_max=100000000"
        />
        <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 mb-n6 justify-content-center">
          {properties &&
            properties
              .filter(({ categories }) => categories.includes(5))
              .map((property) => {
                return (
                  <div
                    key={property.id}
                    className="col mb-6"
                    data-aos="fade-up"
                  >
                    <BlogItem data={property} />
                  </div>
                );
              })}
        </div>
        <div className="row">
          <div className="col-12 text-center my-4">
            <a
              href="https://spaindom.com/listing-properties/?property_type%5B%5D=villa&price_max=100000000"
              target="_blank"
              className="btn btn-primary btn-hover-secondary"
            >
              View all Villas for sale {">>"}
            </a>
          </div>
        </div>

        <SectionTitle
          headingOption="fz-32 mt-15 mb-5"
          title="Luxury Apartments for sale"
          subTitle=""
          link="https://spaindom.com/listing-properties/?property_type%5B%5D=apartment&price_max=100000000"
        />
        <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 mb-n6 justify-content-center">
          {properties &&
            properties
              .filter(({ categories }) => categories.includes(4))
              .map((property) => {
                return (
                  <div
                    key={property.id}
                    className="col mb-6"
                    data-aos="fade-up"
                  >
                    <BlogItem data={property} />
                  </div>
                );
              })}
        </div>
        <div className="row">
          <div className="col-12 text-center my-4">
            <a
              href="https://spaindom.com/listing-properties/?property_type%5B%5D=apartment&price_max=100000000"
              target="_blank"
              className="btn btn-primary btn-hover-secondary"
            >
              View all Apartments for sale {">>"}
            </a>
          </div>
        </div>
        <SectionTitle
          headingOption="fz-32 mt-15 mb-5"
          title="Unique Opportunities for sale"
          subTitle=""
          link="https://spaindom.com/listing-properties/"
        />
        <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 mb-n6 justify-content-center">
          {properties &&
            properties
              .filter(({ categories }) => categories.includes(3))
              .map((property) => {
                return (
                  <div
                    key={property.id}
                    className="col mb-6"
                    data-aos="fade-up"
                  >
                    <BlogItem data={property} />
                  </div>
                );
              })}
        </div>
        <div className="row">
          <div className="col-12 text-center my-4">
            <a
              href="https://spaindom.com/listing-properties/"
              target="_blank"
              className="btn btn-primary btn-hover-secondary"
            >
              View all Opportunities for sale {">>"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBlog;
