import React from 'react'

const GoogleMap = () => {
    return (
        <div className="google-map-area section text-center section-padding-bottom">
            <div className="container">
                <div className="contact-map-area" data-aos="fade-up">
                    <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12829.252559040598!2d-4.9894922!3d36.4983025!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe5fef95e652a73d2!2sSpainGrupo!5e0!3m2!1ses!2ses!4v1623400155351!5m2!1ses!2ses" loading="lazy"></iframe>
                </div>
            </div>
        </div>
    )
}

export default GoogleMap;
