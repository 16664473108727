import React, { useState } from "react";
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import ModalVideo from "react-modal-video";

const Video = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <div className="video-section section section-padding overlay-two" style={{
                paddingTop: '150px',
                paddingBottom: '150px',   
                backgroundSize: 'cover',
                backgroundPosition: 'center', 
                backgroundImage: `url(https://res.cloudinary.com/ddwoawsvk/image/upload/v1623401800/IMG_6772-EFFECTS-1-1-e1510597840245_1_lig8wk.jpg)`
            }}>

            <div className="container text-center">

                <div className="section-title color-light text-center">
                    <h2 className="title">Costa del Sol</h2>
                </div>
                <SectionTitle
                    titleOption="color-light text-center"
                    title="Discover The Paradise"
                />

                <button className="play-btn icon video-popup" onClick={()=> setOpen(true)}><i className="fas fa-play"></i></button>
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="po2QM2VrY1I" onClose={() => setOpen(false)}/>

            </div>
        </div>
    )
}

export default Video;
