import React from 'react';
import PropTypes from "prop-types"
import {Link} from "react-router-dom";


const Btn = ({name, to = '/'}) => {
    return (
        <React.Fragment>
            <Link to={process.env.PUBLIC_URL + to } className="btn btn-light btn-hover-primary">{name}</Link>
        </React.Fragment>
    )
}

Btn.propTypes = {
    name: PropTypes.string,
    to: PropTypes.string
}

export default Btn
