import PropTypes from "prop-types";
import React from "react";
export function formatPrice(num, withSymbol = true) {
  return (
    num &&
    num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") +
      (withSymbol ? " €" : "")
  );
}

const BlogItem = ({ data }) => {
  console.log("Data aa", data);
  const title = data.title.rendered;
  const link = "https://spaindom.com/property/" + data.slug;
  const { image, bedrooms, bathrooms, built, price } = data.acf;

  const formatedPrice = formatPrice(price);

  return (
    <div className="blog property-result">
      {image && (
        <div className="thumbnail">
          <a href={link} target="_blank" className="image">
            <img src={image.sizes.medium} alt="Property Image" />
          </a>
        </div>
      )}
      <div className="info text-center">
        <h6 className="title">
          <a
            href={link}
            target="_blank"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </h6>
        <ul className="meta">
          <li className="meta-item">
            <i className="far fa-bed"></i>
            {bedrooms}
          </li>
          <li className="meta-item">
            <i className="far fa-bath"></i>
            {bathrooms}
          </li>
          <li className="meta-item">
            <i className="far fa-ruler-combined"></i>
            {built}
          </li>
        </ul>
        <h4 className="price">{formatedPrice}</h4>
      </div>
    </div>
  );
};

BlogItem.propTypes = {
  data: PropTypes.object,
};

export default BlogItem;
