import AOS from "aos";

import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavScrollTop from "./components/NavScrollTop";
import About from "./pages/About";
import BlogGrid from "./pages/BlogGrid";
import Contact from "./pages/Contact";
import HomeTwo from "./pages/HomeTwo";
import Service from "./pages/Service";
import Work from "./pages/Work";
import WorkDetails from "./pages/WorkDetails";
import AccountContext from "./contexts/AccountContext";
import { useParams } from "react-router-dom";

// CSS File Here
import "aos/dist/aos.css";
import "react-modal-video/scss/modal-video.scss";
import "./assets/scss/style.scss";
import React from "react";
import PrivateAssets from "./pages/PrivateAssets";
import useAccount from "./hooks/useAccount";
import PrivateSingleAsset from "./pages/PrivateSingleAsset";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);
  const accountData = useAccount();

  return (
    <Router>
      <AccountContext.Provider value={accountData}>
        <NavScrollTop>
          <Switch>
            <Route
              path={`${process.env.PUBLIC_URL + "/"}`}
              exact
              component={HomeTwo}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/asset-management/:id"}`}
              component={WorkDetails}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/asset-management"}`}
              component={Service}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/private/:id"}`}
              component={PrivateSingleAsset}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/private"}`}
              component={PrivateAssets}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/residential"}`}
              component={About}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/commodities"}`}
              component={Work}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/asset-details/:id"}`}
              component={WorkDetails}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/innovation"}`}
              component={BlogGrid}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              component={Contact}
            />
            {/* <Route component ={NotFound} /> */}
          </Switch>
        </NavScrollTop>
      </AccountContext.Provider>
    </Router>
  );
}

export default App;
