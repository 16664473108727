const getCommercials = async (signal) => {
  const url =
    "https://control.spaingrupo.com/wp-json/wp/v2/pages/?per_page=100";

  const resp = await fetch(url, { signal: signal });
  const returner = await resp.json();

  return returner || [];
};

export default getCommercials;
