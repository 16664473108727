import React, { useContext } from "react";
import ScrollToTop from "../components/ScrollToTop.jsx";
import SectionTitle from "../components/SectionTitles/SectionTitle";
import SEO from "../components/SEO";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Login from "../container/ContactInformation/Login.js";
import Footer from "../container/Footer/Footer";
import SingleAssetTwoPhotos from "../container/Work/SingleAssetTwoPhotos";
import useGetPrivateCommercials from "../hooks/useGetPrivateCommercials.js";
import Header from "../partials/header/Header";
import AccountContext from "../contexts/AccountContext.js";
import { Link } from "react-router-dom";
const PrivateAssets = () => {
  const { password } = useContext(AccountContext);
  const commercials = useGetPrivateCommercials(password);

  return (
    <React.Fragment>
      <SEO title="Assets Management - SpainGrupo, Investments in Spain" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-three.jpg"
        title="We work with the most relevant assets in the market"
        content="Home"
        contentTwo="Asset Management"
      />
      <SectionTitle
        id="main"
        headingOption="fz-32 mt-8 mb-5"
        title="Discover our PRIVATE collection of investments"
        subTitle="The key is that we treat each client individually,
                    proposing to you only those in which we trust"
      />

      {(password &&
        Array.isArray(commercials) &&
        commercials.map((single) => {
          return (
            <div className="text-center" key={single.id}>
              <Link
                to={process.env.PUBLIC_URL + "/asset-management/#main"}
                className="m-auto btn btn-primary"
              >
                {"<<"} Back to all the assets
              </Link>
              <SingleAssetTwoPhotos data={single} key={single.id} />
            </div>
          );
        })) || <Login />}

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default PrivateAssets;
