import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const NavScrollTop = (props) => {
  const pathname = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children;
};

export default withRouter(NavScrollTop);
