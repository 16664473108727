import React from "react";
import SEO from "../components/SEO";
import ScrollToTop from "../components/ScrollToTop.jsx";
import HomeAboutThree from "../container/About/HomeAboutThree";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import ServiceIconBox from "../container/service/ServiceIconBox";
import Header from "../partials/header/Header";

const Work = () => {
  return (
    <React.Fragment>
      <SEO title="Commodities - SpainGrupo, Investments in Spain" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-two.jpg"
        title="Energy and commodities supply"
        content="Home"
        contentTwo="commodities"
      />
      <HomeAboutThree
        title="Oil Derivatives"
        info={true}
        description="We have very competitive rates with discount on PLATTS or even fix price revised periodically. Our reliability comes from strong key partnerships with more than 20 years of experience.
                We have the ability to facilitate FOB and preferably CIF in Rotterdam and Houston."
      />
      <ServiceIconBox />
      {/* <Faq /> */}
      <CallToAction
        title="Need more information?"
        desc="Contact us and request more info about all our private produtcs or our buying process. We will be happy to advise you on any question you may have."
        btnLink="contact"
        btnLabel="Get in Touch"
        bg="https://res.cloudinary.com/ddwoawsvk/image/upload/v1623367223/newsletter_x8card.png"
      />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Work;
