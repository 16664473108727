import { Link } from "react-router-dom";
import React from "react";

const SingleAssetTwoPhotos = ({ data }) => {
  const publicUrl = process.env.PUBLIC_URL;
  const assetPath =
    data.status === "publish" ? "/asset-management/" : "/private/";
  return (
    <div className="video-section section section-padding-small">
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-xxl-7 col-xl-6 col-lg-5 col-12"
            data-aos="fade-up"
          >
            <div className="about-image-area-alt">
              <div className="about-image-alt">
                <img src={data.acf.gallery[0]} alt="" />
              </div>
              <div className="about-image-alt">
                <img src={data.acf.gallery[1]} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-xxl-5 col-xl-6 col-lg-7 col-12 white-card"
            data-aos="fade-up"
          >
            <div className="skill-with-video-content">
              <div className="section-title-two mb-8">
                <span className="sub-title alt">Investment</span>
                <h3 className="title max-title">{data.title.rendered}</h3>
              </div>

              <ul className="agency-list">
                {data.acf.bullets.map((bullet) => (
                  <li className="item" key={bullet.content}>
                    <div className="icon">
                      <i className="fas fa-check"></i>
                    </div>
                    <div className="text">{bullet.content}</div>
                  </li>
                ))}
              </ul>
              <Link
                to={publicUrl + assetPath + data.id}
                className="btn btn-primary btn-hover-secondary"
              >
                View More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleAssetTwoPhotos;
