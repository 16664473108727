import React, { useState } from "react";
import {Link} from "react-router-dom";
import ModalVideo from "react-modal-video";



const HomeSkillWithVideo = () => {
    const [isOpen, setOpen] = useState(false)

    return (
        <div className="video-section section section-padding">

            <div className="container">

                <div className="row align-items-center">
                    <div className=" col-xl-6 col-lg-6 col-12">
                        <div className="video-popup-area">
                            <div className="skill-video">
                                <img className="image" src="https://res.cloudinary.com/ddwoawsvk/image/upload/v1622918449/9d23a014-41e0-40c0-bd17-54c60e2291c2_cr3ssu.jpg" alt="video popup" />
                                <button className="icon" onClick={()=> setOpen(true)}><i className="fas fa-play"></i></button>
                                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="po2QM2VrY1I" onClose={() => setOpen(false)}/>
                            </div>
                        </div>
                    </div>
                    <div className="offset-xl-1 col-xl-5 col-lg-6 col-12" data-aos="fade-up">
                        <div className="skill-with-video-content">
                            <div className="section-title-two mb-8">
                                <span className="sub-title">An array of possibilities in a paradise</span>
                                <h3 className="title">We believe that the course of life should be on par with economical growth</h3>
                            </div>

                            <ul className="agency-list">
                                <li className="item">
                                    <div className="icon">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <div className="text">A place you can call home</div>
                                </li>
                                <li className="item">
                                    <div className="icon">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <div className="text">A business you can see and feel</div>
                                </li>
                                <li className="item">
                                    <div className="icon">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <div className="text">A commodity you see grow and bring happiness</div>
                                </li>
                                <li className="item">
                                    <div className="icon">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <div className="text">Be part of something unique</div>
                                </li>
                            </ul>
                            <Link to={process.env.PUBLIC_URL + "/contact"} className="btn btn-primary btn-hover-secondary"> Join Us</Link>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}



export default HomeSkillWithVideo;
