import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import React from "react";

const AboutFive = ({
  title = "",
  subtitle = "",
  description = "",
  link = "",
  firstImage = "",
  secondImage = "",
}) => {
  return (
    <div className="section section-padding-bottom">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-image-area d-none d-lg-block">
              <div className="about-image">
                <img src={firstImage} alt={title} />
              </div>
              <div className="about-image">
                <img src={secondImage} alt={title} />
              </div>
            </div>
            <a target="_blank" href={link} className="d-block d-lg-none">
              <img src={secondImage} alt={title} />
            </a>
          </div>

          <div
            className="col-xl-5 col-lg-6 col-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="about-content-area">
              <SectionTitleTwo subTitle={subtitle} title={title} />

              <p>{description}</p>

              <a
                className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4"
                target="_blank"
                href={link}
              >
                Visit Website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
