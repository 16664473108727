import React from "react";
import contactDataTwo from '../../data/contactInfo/contactInfoTwo.json';
import ContactInfoItem from '../../components/ContactInfo/ContactInfoItemTwo.jsx';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo.jsx';
import ProjectForm from '../../components/ProjectForm/ProjectForm';


const ContactInformationThree = () => {
    return (
        <div id="request-more-info" className="section section-padding contact-section overlay-two" style={{backgroundImage: `url(https://res.cloudinary.com/ddwoawsvk/image/upload/v1623396372/contact-bg_1_bkz0oc.png)`}}>

            <div className="container">
                <div className="row row-cols-lg-2 row-cols-1 align-items-center">
                    <div className="col" data-aos="fade-up">
                        <div className="contact-Information mr-xl-7">
                            <SectionTitleTwo 
                                classOption="color-light"
                                subTitle="Discover all our range of products"
                                title="We also have more exclusive and private investment:"
                            />

                            <div className={`contact-info info-light`} >
                                <div className="info">
                                    <h4 className="title">Luxury Hotels</h4>
                                    <span className="info-text">In Madrid, Barcelona, Málaga, Marbella, Galicia, Chipre & London</span>
                                </div>
                            </div>
                            <div className={`contact-info info-light`} >
                                <div className="info">
                                    <h4 className="title">Shopping Centres</h4>
                                    <span className="info-text">A large and luxury chain of shopping centres in Spain</span>
                                </div>
                            </div>
                            <div className={`contact-info info-light`} >
                                <div className="info">
                                    <h4 className="title">Vineyards</h4>
                                    <span className="info-text">Field of organic vineyards</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col mt-lg-0 mt-md-10 mt-8" data-aos="fade-up" data-aos-delay="300">
                        <div className="contact-form-area">
                            <SectionTitle
                                titleOption="section-title text-center mb-7"
                                headingOption="title fz-28"
                                title="Request Exclusive Access"
                                subTitle="Fill the form and request exclusive access to all our private portfolio of assets"
                            />

                            <ProjectForm />
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ContactInformationThree;
