import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React from "react";

const Breadcrumb = ({ title, content, contentTwo, image, rawImage }) => {
  const publicUrl = process.env.PUBLIC_URL;
  return (
    <div className="bc-round-shape">
      <div
        className="page-title-section section section-padding-top overlay-two noindex"
        style={{
          backgroundImage: `url(${rawImage || publicUrl + "/" + image})`,
        }}
      >
        <div className="page-title hz122">
          <div className="container">
            <h1 className="title">{title}</h1>
          </div>
        </div>
        <div className="page-breadcrumb hz122">
          <div className="container">
            <ul className="breadcrumb justify-content-center">
              <li>
                <Link to={process.env.PUBLIC_URL + "/"}>{content}</Link>
              </li>
              <li className="current">{contentTwo}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="svg-shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="elementor-shape-fill"
            d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  contentTwo: PropTypes.string,
  image: PropTypes.string,
};

export default Breadcrumb;
