import PropTypes from "prop-types";

const WorkDetailsContainer = ({ data }) => {
  if (!data.id) {
    return null;
  }
  return (
    <div className="section section-padding">
      <div className="container">
        <div className="row pt--100 pb--80">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="work-left work-details" data-aos="fade-up">
              <div className="portfolio-main-info">
                <h2 className="title">{data.title.rendered}</h2>
                <div className="work-details-list mt-12">
                  {Array.isArray(data.acf.keys) &&
                    data.acf.keys.map(({ key, value }) => (
                      <div className="details-list" key={key + "." + value}>
                        <label>{key}</label>
                        <span>{value}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
            <div className="work-left work-details mt-6">
              <div className="work-main-info">
                <div className="work-content">
                  <h6 className="title" data-aos="fade-up">
                    Key Points
                  </h6>

                  <div className="desc mt-8">
                    {data.acf.bullets.map((value, i) => {
                      return (
                        <div
                          key={value.content}
                          className="content mb-5"
                          data-aos="fade-up"
                        >
                          <i className="fas fa-check" /> {value.content}
                        </div>
                      );
                    })}

                    {data.acf.download && (
                      <div className="work-btn">
                        <a
                          className="btn btn-primary btn-hover-secondary"
                          href={data.acf.download}
                          target="_blank"
                        >
                          Download Document
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {data.acf.description && (
          <div className="row mt-lg-20 mt-12">
            <div className="col-lg-4 col-md-12 col-12">
              <div className="digital-marketing" data-aos="fade-up">
                <h3 className="heading heading-h3">Description</h3>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
              <div
                className="digital-marketing mt-lg-0 mt-6"
                data-aos="fade-up"
              >
                <div
                  className="inner"
                  dangerouslySetInnerHTML={{ __html: data.acf.description }}
                />
              </div>
            </div>
          </div>
        )}

        <div className="custom-layout-gallery mt-lg-20 mt-12">
          <div className="row">
            {Array.isArray(data.acf.gallery) &&
              data.acf.gallery.map((image) => (
                <div className="col-lg-6 col-md-6 col-12 mb-6">
                  <div className="thumbnail" data-aos="fade-up">
                    <img
                      className="w-100 ratioed"
                      src={image}
                      alt="Agency"
                      style={{ aspectRatio: "3 / 2", objectFit: "cover" }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

WorkDetailsContainer.propTypes = {
  data: PropTypes.object,
};

export default WorkDetailsContainer;
