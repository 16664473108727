import PropTypes from "prop-types";
import React from 'react';

const IconBox = ({ data, classOption }) => {
    return (
        <div className={`text-center ${classOption}`}>
            <div className="icon">
                <img src={data.img} alt={data.title}/>
            </div>
            <div className="content mt-5">
                <h3 className="title">{data.title}</h3>
                <div className="desc">
                    <p>{data.desc}</p>
                </div>
            </div>
        </div>
    )
}

IconBox.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string
};

IconBox.defaultProps = {
    classOption: "icon-box text-center",
};

export default IconBox;