import { useEffect, useState } from "react";
import getPrivateCommercials from "../utils/getPrivateCommercials";
const useGetPrivateCommercials = (password) => {
  const [commercials, setCommercials] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (password) {
      getPrivateCommercials(password, signal).then((commercials) => {
        setCommercials(commercials);
      });
    }

    return () => abortController.abort();
  }, [password]);

  return commercials;
};

export default useGetPrivateCommercials;
