import React from "react";
import ScrollToTop from "../components/ScrollToTop.jsx";
import SEO from "../components/SEO";
import AboutFour from "../container/About/AboutFour";
import PageBlog from "../container/BlogGrid/PageBlog";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import ContactInformationTwo from "../container/ContactInformation/ContactInformationTwo";
import Footer from "../container/Footer/Footer";
import Video from "../container/Video/Video";
import Header from "../partials/header/Header";

const AboutUs = () => {
  return (
    <React.Fragment>
      <SEO title="Residential & Real Estate - SpainGrupo, Investments in Spain" />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg.jpg"
        title="Residential Properties"
        content="Home"
        contentTwo="Residential"
      />
      <AboutFour />
      <PageBlog />
      <Video />
      <ContactInformationTwo />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default AboutUs;
