import { useEffect, useState } from "react";
import getCommercials from "../utils/getCommercials";
const useGetCommercials = () => {
  const [commercials, setCommercials] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    getCommercials(signal).then((commercials) => {
      setCommercials(commercials);
    });

    return () => abortController.abort();
  }, []);

  return commercials;
};

export default useGetCommercials;
