import React, { useState, useEffect }  from 'react';
import YoutubeBackground from 'react-youtube-background'

const IntroTwo = () => {
    const [scroll, setScroll] = useState(1);

    /* const onScroll = (e) => {
        const delta = e.deltaY * +0.01;
        const newScale = scale + delta;
        setPos( newScale);
    }; */

    const handleScroll = () => {
        setScroll(scroll + (window.scrollY * 0.005));
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div className="section__hero" >
                { scroll < 15 && (
                    <div className="hero__wrapper">
                        
                        <a href="#cases" className="hero__animationfont w-inline-block">
                            <div className="hero__subline">
                                {/* SpainGrupo */}<br />
                            </div>
                            <h1 className="h1__hero__wrapper">
                                <span className="h1__hero">
                                    The wise choice for
                                </span>
                                <span className="h1__hero _2">
                                    Investments in Spain
                                </span>
                                <br />
                            </h1>
                            <div href="#trigger" className="arrow__hero">
                            </div>
                        </a> 
                        
                        <div className="hero__animation__wrapper" style={{transform: `scale(${scroll})`}}>
                            <a href="#cases" className="kreis__gross w-inline-block" >
                                <div className="bg__video w-background-video w-background-video-atom">
                                    <YoutubeBackground
                                        videoId="APji7hMFPD8"
                                        overlay="rgba(0,0,0,.4)"
                                        className="intro-section bg-video" 
                                    />
                                </div>
                                <div className="kreis__klein" >
                                </div>
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default IntroTwo;