const getCommercial = async (id, signal) => {
  const url = "https://control.spaingrupo.com/wp-json/wp/v2/pages/" + id + "/";

  const resp = await fetch(url, { signal: signal });
  const returner = await resp.json();

  return returner || [];
};

export default getCommercial;
