import PropTypes from "prop-types";
import React from "react";

const CounterUpItem = ({ data }) => {
  /* 
    const [didViewCountUp, setDidViewCountUp] = useState(false);

    const onVisibilityChange = isVisible => {
        if (isVisible) {
        setDidViewCountUp(true);
        }
    }; */
  return (
    <div className="funfact">
      <div className="number">{data.number}</div>
      <h6 className="text">{data.text}</h6>
    </div>
  );
};

CounterUpItem.propTypes = {
  data: PropTypes.object,
};

export default CounterUpItem;
