import { useState } from "react";
import checkLogin from "../utils/checkLogin";

const useAccount = () => {
  const [account, setAccount] = useState({
    mail: false,
    password: false,
  });

  const [loading, setLoading] = useState(false);

  const doLogin = (password, mail) => {
    setLoading(true);

    checkLogin(password, mail).then((response) => {
      if (response) {
        setAccount((oldAccount) => ({
          ...oldAccount,
          password,
          mail,
        }));
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return { doLogin, setAccount, loading, ...account };
};

export default useAccount;
