import React from "react";

const ContactFrom = () => {
  return (
    <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
      <form
        action="https://getform.io/f/254e858f-dcf1-49af-bdb6-f033def85d63"
        method="POST"
      >
        <div className="row mb-n6">
          <div className="col-md-6 col-12 mb-6">
            <input type="text" placeholder="Your Name *" name="name" />
          </div>
          <div className="col-md-6 col-12 mb-6">
            <input type="email" placeholder="Email *" name="email" />
          </div>
          <div className="col-md-12 col-12 mb-6">
            <input type="text" placeholder="Phone *" name="phone" />
          </div>
          <div className="col-12 mb-6">
            <textarea name="message" placeholder="Message"></textarea>
          </div>
          <div className="col-12 text-center mb-6">
            <button
              type="submit"
              className="btn btn-primary btn-hover-secondary"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactFrom;
