import { useEffect, useState } from "react";
import getProperties from "../utils/getProperties";
const useGetProperties = () => {
  const [commercials, setProperties] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    getProperties(signal).then((commercials) => {
      setProperties(commercials);
    });

    return () => abortController.abort();
  }, []);

  return commercials;
};

export default useGetProperties;
