import PropTypes from "prop-types";
import React from 'react';
import ServiceBoxData from '../../data/serviceBox/service-box.json';
import ServiceBox from '../../components/ServiceBox/ServiceBox.jsx';
import SectionTitle from '../../components/SectionTitles/SectionTitle';

const ServiceHomeBox = ({ classOption }) => {
    return (
        <div className={`section section-padding-t90-b100 ${classOption}`}>
            <div className="container">

                <SectionTitle
                    title='We are "the" boutique firm'
                    subTitle="With a highly specialized team for each of our 4 branches"
                />

                <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1  mt-50 mt-zero-margin">

                    {ServiceBoxData && ServiceBoxData.slice(0, 4).map((single, key) => {
                        return(
                            <div key={single.id} className={`col px-1 ${ (key % 2 ==0) ? 'mt-n25' : 'mt-25'} mt-margin`} data-aos="fade-up">
                                <ServiceBox classOption="box-border" data={single} key={single.id} />
                            </div>
                        ); 
                    })}

                </div>

            </div>
        </div>
    )
}

ServiceHomeBox.propTypes = {
    classOption: PropTypes.string
};
ServiceHomeBox.defaultProps = {
    classOption: "section section-padding-t90-b100"
};

export default ServiceHomeBox;
