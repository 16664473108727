import { useEffect, useState } from "react";
import getCommercial from "../utils/getCommercial";
const useGetCommercial = (id) => {
  const [commercial, setCommercial] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    getCommercial(id, signal).then((commercial) => {
      setCommercial(commercial);
    });
    return () => abortController.abort();
  }, []);

  return commercial;
};

export default useGetCommercial;
