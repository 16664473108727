import React from "react";
import contactDataTwo from "../../data/contactInfo/contactInfoTwo.json";
import ContactInfoItem from "../../components/ContactInfo/ContactInfoItemTwo.jsx";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo.jsx";
import ProjectForm from "../../components/ProjectForm/ProjectForm";
import PasswordForm from "../../components/ProjectForm/PasswordForm";

const Login = () => {
  return (
    <div
      id="request-more-info"
      className="section section-padding-small contact-section"
    >
      <div className="container">
        <div className="row row-cols-lg-2 row-cols-1 align-items-center">
          <div className="col" data-aos="fade-up">
            <div className="contact-Information mr-xl-7">
              <SectionTitleTwo
                subTitle="Discover all our range of products"
                title="We also have more exclusive and private investment:"
              />

              <div className={`contact-info info-dark`}>
                <div className="info">
                  <h4 className="title">Luxury Hotels</h4>
                  <span className="info-text">
                    In Madrid, Barcelona, Málaga, Marbella, Galicia, Chipre &
                    London
                  </span>
                </div>
              </div>
              <div className={`contact-info info-dark`}>
                <div className="info">
                  <h4 className="title">Shopping Centres</h4>
                  <span className="info-text">
                    A large and luxury chain of shopping centres in Spain
                  </span>
                </div>
              </div>
              <div className={`contact-info info-dark`}>
                <div className="info">
                  <h4 className="title">Vineyards</h4>
                  <span className="info-text">Field of organic vineyards</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col mt-lg-0 mt-md-10 mt-8"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="contact-form-area">
              <SectionTitle
                titleOption="section-title text-center mb-7"
                headingOption="title fz-28"
                title="Login with your account"
                subTitle="If recieved the access details, login here to see our private asset collections"
              />

              <PasswordForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
